.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #f3f4f6; // Light gray fallback
}

.placeholder {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  transform: scale(1.1);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;

  &.imageLoading {
    // opacity: 0;
    filter: blur(10px);
  }

  &.imageLoaded {
    // opacity: 1;
    filter: blur(0);
  }
}